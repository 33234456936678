import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { type NextBestAction } from '@post-office/confluence-next-best-action';
// import { TestPlacement } from '@post-office/placements--growth/confluence-side-nav/test';
import { Placement as ConfluenceSideNav } from '@post-office/placements/confluence-side-nav';
import { useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import {
	PlacementFetchProvider,
	usePlacementPreloadedData,
} from '@atlassian/confluence-post-office-provider/entry-points/preload-placement';

import { buildCreationLink } from '@confluence/create-blank-fabric-page';
import { useCreateSpaceStore } from '@confluence/create-space/entry-points/createSpace';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { fg } from '@confluence/feature-gating';
import { expValEquals } from '@confluence/feature-experiments';
import { RoutesContext } from '@confluence/route-manager';

const PO_CONFLUENCE_SIDE_NAV = 'poConfluenceSideNavCreate';

const getIsNav4AndEnabled = () => {
	if (fg('confluence_nav4_ga_phase_1')) {
		return fg('post-office-connie-side-nav-v4');
	}

	// If we are not using nav4, return true
	return true;
};

type SideNavClickHandler = (nextBestActionType: NextBestAction) => void;

const nav4ContainerStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginBottom: '10px',
});

export const PostOfficeConfluenceSideNavPlacement = ({ nav4 = false }) => {
	const preloadedInitialData = usePlacementPreloadedData('confluence-side-nav');
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();

	const { push } = useContext(RoutesContext);
	const [, { openDrawer }] = useCreateSpaceStore();
	const sideNavRef = useRef(null);

	const onSideNavClick = useCallback<SideNavClickHandler>(
		(nextBestActionType: NextBestAction) => {
			switch (nextBestActionType) {
				case 'CREATE_PAGE': {
					push(
						buildCreationLink({
							contentType: 'page',
							source: PO_CONFLUENCE_SIDE_NAV,
						}),
					);
					break;
				}
				case 'CREATE_WHITEBOARD': {
					push(
						buildCreationLink({
							contentType: 'whiteboard',
							source: PO_CONFLUENCE_SIDE_NAV,
						}),
					);
					break;
				}
				case 'CREATE_SPACE': {
					// The second argument for openDrawer is the location string, which can be undefined or 'topCreateNav'
					// Since we're using the side nav to open the drawer, undefined is passed here.
					openDrawer(sideNavRef, undefined);
					break;
				}
				default:
					return;
			}
		},
		[openDrawer, push],
	);

	const memoizedPlacementProps = useMemo(
		() => ({
			preloadedInitialData,
			onSideNavClick,
		}),
		[preloadedInitialData, onSideNavClick],
	);

	const { locale } = useIntl();
	const isEnglishLocale = locale.toLowerCase().startsWith('en');

	const isNav4AndEnabled = getIsNav4AndEnabled();

	const PlacementInternals = () => (
		<PlacementFetchProvider>
			{/* @ts-expect-error: Props are not type checked */}
			<ConfluenceSideNav {...memoizedPlacementProps} />
		</PlacementFetchProvider>
	);

	if (!fg('post-office-connie-side-nav-killswitch') || !isNav4AndEnabled) {
		return <></>;
	}

	if (
		isEnglishLocale &&
		expValEquals('post-office_expt_confluence-next-best-actions', 'cohort', 'variation')
	) {
		return nav4 ? (
			<div
				data-testid="confluence-side-nav-placement"
				data-vc="confluence-side-nav-placement"
				{...ssrPlaceholderIdProp}
				css={nav4ContainerStyles}
			>
				<PlacementInternals />
			</div>
		) : (
			<div
				data-testid="confluence-side-nav-placement"
				data-vc="confluence-side-nav-placement"
				{...ssrPlaceholderIdProp}
			>
				<PlacementInternals />
			</div>
		);
	}

	return <></>;
};
