import { useMessageInstanceEvent } from '@post-office/message-instance-event-frontend-client';
import { useMessageEvent } from '@post-office/message-lifecycle';
import { type ClickedElementAttributes } from '@post-office/shared-contracts';
import { useCallback } from 'react';

export const useActionMessageClicked = (
	onMessageClick: (e: React.MouseEvent<HTMLElement>) => void,
	clickedElement: ClickedElementAttributes,
) => {
	const { messageClicked } = useMessageEvent();
	const { messageInstanceClicked } = useMessageInstanceEvent();

	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			messageClicked().catch(() => {});
			messageInstanceClicked({
				messageEventType: 'clicked',
				clickedElement,
				action: {
					actionType: 'acknowledge',
				},
				additionalMetadataFromFrontend: {},
			}).catch(() => {});
			onMessageClick(e);
		},
		[clickedElement, messageClicked, messageInstanceClicked, onMessageClick],
	);

	return { handleClick };
};
